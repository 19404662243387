import { useState } from 'react';
import data from '../store/data';

const AllData = (props) => {
   const [collapse, setcollapse] = useState('hidden');
   const [arrow, setArrow] = useState('rotate-180');

   const collapseHandler = () => {
      if (collapse === 'hidden' && arrow === 'rotate-180') {
         setcollapse('');
         setArrow('rotate-0');
      } else {
         setcollapse('hidden');
         setArrow('rotate-180');
      }
   };

   return (
      <section className="w-full transition-all duration-300">
         <div className="bg-slate-100 py-2 px-4 rounded-md mb-2 cursor-pointer" onClick={collapseHandler}>
            <div className="flex items-center justify-between">
               <p className="flex items-center justify-center w-7 h-7 rounded-full bg-slate-300">{props.id}</p>
               <p>quantity: {props.quantity}</p>
               <div className="flex items-center gap-4">
                  <input type="checkbox" className="form-check-input h-4 w-4 cursor-pointer" value={props.id} />
                  <span className={`transition-all duration-300 ${arrow}`}>
                     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
                  </span>
               </div>
            </div>
         </div>
         <ul className={collapse}>
            {data.filter(detail => detail.id === props.id).map((detail) => (
               <li className={`flex items-center justify-between mb-2 py-2 px-4 rounded-md  bg-slate-300`} key={detail.uniqueId}>
                  <p>#{detail.uniqueId}</p>
                  <p>{detail.created.substring(0, 10).replace(/-/g, "/")}</p>
                  <input
                     type="checkbox"
                     className="form-check-input h-4 w-4 cursor-pointer"
                     value={detail.uniqueId}
                     onChange={props.handleCheck}
                  />
               </li>
            ))}
         </ul>
      </section>
   );
}

export default AllData;