import AllData from './component/AllData';

const App = () => {
  return (
    <>
      <AllData />
    </>
  );
}

export default App;