import { useState } from 'react';
import Data from './Data';
import SelectedData from './SelectedData';
import data from '../store/data';

const AllData = () => {
   const [checked, setChecked] = useState([]);

   // Add/Remove checked item from list
   const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
         updatedList = [...checked, event.target.value];
      } else {
         updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
   };

   // Generate string of checked items
   const checkedItems = checked.length
      ? checked.reduce((total, item) => {
         return total + ", " + item;
      })
      : "";

   const dataId = [['A', 5], ['B', 3], ['C', 5], ['D', 4], ['E', 3]];

   return (
      <div className='grid grid-cols-12 p-4 gap-4 min-h-[800px] max-w-[2000px] mx-auto'>
         <div className='col-span-12 md:col-span-6 flex flex-col items-start justify-start bg-slate-200 p-4 md:p-8 rounded-md'>
            <h2 className='self-center text-3xl mb-2'>All Data</h2>
            <button
               type='submit'
               className='self-end py-2 px-6 bg-slate-300 rounded-md font-medium mb-6'
               uniqueId={data.uniqueId}
            >
               Add
            </button>
            {dataId.map(index => (
               <Data
                  id={index[0]}
                  quantity={index[1]}
                  key={index}
                  handleCheck={handleCheck}
                  checkedItems={checkedItems}
               />
            ))}
         </div>

         <div className='col-span-12 md:col-span-6 flex flex-col items-start justify-start bg-slate-200 p-4 md:p-8 rounded-md'>
            <h2 className='self-center text-3xl mb-2'>Selected Data</h2>
            <button type='submit' className='self-end py-2 px-6 bg-slate-300 rounded-md font-medium mb-6 opacity-50 cursor-not-allowed'>Delete</button>
            <SelectedData checkedItems={checkedItems} />
         </div>
      </div>
   );
}

export default AllData;